import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import UserDetailPage from "./UserDetailPage";
import Project from "../../../models/Project";
import User from "../../../models/User";
import { getBluechipResourceById } from "../../../utils/bluechipUtils";

import * as REQUEST_TYPES from "../../../constants/RequestTypes";

import {
  initDataComponent,
  performFindRequest,
} from "../../../actions/dataComponentActions";
import { openUserSettingsModal } from "../../../actions/usersActions";

import { getDataComponentFlattenedRequestState } from "../../../reducers/dataComponentReducer";
import { push } from "connected-react-router";

const dataComponentId = "UserDetail";
const projectDataComponentId = "UserProjectsGrid";

export class UserDetailContainer extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    user: PropTypes.object,
    userId: PropTypes.string.isRequired,
    openUserSettingsModal: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    initDataComponent: PropTypes.func.isRequired,
    performFindRequest: PropTypes.func.isRequired,
  };

  handleUserSettingsClick = () => {
    const { openUserSettingsModal, user } = this.props;
    openUserSettingsModal(user);
  };

  handleRowClick = ({
    id,
    property: {
      entity: { clientId },
    },
  }) => {
    this.props.push(`/clients/${clientId}/projects/${id}`);
  };

  componentDidMount() {
    const { initDataComponent, performFindRequest, userId } = this.props;

    initDataComponent(
      dataComponentId,
      User,
      ["userRole", "team", "office"],
      "users"
    );
    performFindRequest(dataComponentId, userId);
  }

  render() {
    return (
      <UserDetailPage
        {...this.props}
        onUserSettingsClick={this.handleUserSettingsClick}
        onRowClick={this.handleRowClick}
        model={Project}
        includes={["property.entity", "projectLead.person", "projectMemberUsers.user.person"]}
        apiRoute="projects/"
      />
    );
  }
}

export const mapDispatchToProps = {
  initDataComponent,
  performFindRequest,
  openUserSettingsModal,
  push,
};

export const mapStateToProps = (state, ownProps) => {
  const userId = ownProps.match.params.userId;
  const dataComponent = getDataComponentFlattenedRequestState(
    dataComponentId,
    state,
    REQUEST_TYPES.FIND
  );

  return {
    userId,
    user: getBluechipResourceById(dataComponent, state, userId),
    gridDataComponent: getDataComponentFlattenedRequestState(
      projectDataComponentId,
      state
    ),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserDetailContainer)
);
