import React from "react";
import PropTypes from "prop-types";
import _groupBy from "lodash/groupBy";
import get from "lodash.get";

import { useRelatedRecords } from "./useRelatedRecords";
import Loader from "../ui/Loader/Loader";

export const RelatedRecords = ({
  personId,
  contactRelationId,
  setSendDisabled,
  isUserForm,
}) => {
  const { person, isLoading, relations } = useRelatedRecords(personId);

  const relationGroups = _groupBy(relations, "relationName");
  const hasObservation =
    !isLoading &&
    Object.values(relationGroups).some(relationGroup =>
      relationGroup.some(
        relation =>
          relation.contactRelationId == contactRelationId ||
          (isUserForm && relation.relationName == "User")
      )
    );

  React.useEffect(() => {
    if (isLoading || hasObservation) {
      return setSendDisabled(true);
    }
    setSendDisabled(false);
  }, [hasObservation, isLoading, relationGroups, setSendDisabled]);

  return (
    <div className="related-records">
      <h4>
        Related Records
        <Loader isLoading={isLoading}> - {get(person, "name")}</Loader>
      </h4>
      <ul>
        <Loader isLoading={isLoading}>
          {Object.keys(relationGroups).map(group => (
            <div key={group}>
              <h4>{group}</h4>
              {relationGroups[group].map(({ name, ...relation }) => {
                const hightlight =
                  relation.contactRelationId == contactRelationId ||
                  (isUserForm && relation.relationName == "User");
                return (
                  <li key={name}>
                    <p
                      style={
                        hightlight
                          ? {
                              fontStyle: "italic",
                            }
                          : {}
                      }
                    >
                      {name}
                      {hightlight ? " *" : ""}
                    </p>
                  </li>
                );
              })}
            </div>
          ))}
        </Loader>
      </ul>
      {hasObservation && (
        <p style={{ color: "red" }}>* This person already exists here.</p>
      )}
    </div>
  );
};
RelatedRecords.propTypes = {
  personId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  contactRelationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setSendDisabled: PropTypes.func.isRequired,
  isUserForm: PropTypes.bool,
};
