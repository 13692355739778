import React, { useState } from "react";
import { useFormikContext } from "formik";
import get from "lodash.get";
import { Dialog, Typography } from "@material-ui/core";
import styled from "styled-components";
import PropTypes from "prop-types";
import set from "lodash/set";
import { isEmpty } from "lodash";

import ModalTitle from "../layout/AppLayout/ModalDialog/ModalTitle";
import ActionButtons from "../forms/ActionButtons";
import {
  useCreateProcessRequests,
  useUpdateProcessRequests,
} from "../hooks/useProcessRequest";
import { useDatacomponent } from "../hooks/useDatacomponent";
import { RelatedRecords } from "./RelatedRecords";
import { useModalStateContext } from "../layout/AppLayout/ModalDialog/withModalState";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 730px;
  padding: 24px;
  gap: 24px;
`;

const onError = setUniqueErrors => error => {
  const errors = get(error, "data.errors", []);

  const uniqueErrors = errors
    .filter(error => error.code == "unique" && error.source.includes("person"))
    .map(error => ({
      id: get(error, "detail.id"),
      personPath: error.source
        .replace(".email", "")
        .replace(/sites\[\d+\]\./, ""),
    }));
  setUniqueErrors(uniqueErrors);
};

export const DuplicatePersonDialog = ({
  dataComponentId,
  contactRelationId,
  isUserForm,
}) => {
  const [uniqueErrors, setUniqueErrors] = useState([]);
  const [sendDisabled, setSendDisabled] = useState(true);

  const { setFieldValue, handleSubmit } = useFormikContext();
  const { setModalState, modalState } = useModalStateContext();

  const dataComponent = useDatacomponent(dataComponentId);
  useCreateProcessRequests(dataComponent, {
    onError: onError(setUniqueErrors),
  });
  useUpdateProcessRequests(dataComponent, {
    onError: onError(setUniqueErrors),
  });

  const handleClose = () => {
    setUniqueErrors([]);
  };

  const handleConfirm = () => {
    handleClose();

    uniqueErrors.forEach(({ id, personPath }) => {
      if (modalState.contacts) {
        const obj = { ...modalState };
        set(obj, `${personPath}.id`.replace("person", "contact.person"), id);
        setModalState(obj);
      } else {
        setFieldValue(`${personPath}.id`, id);
      }
    });

    handleSubmit();
  };

  return (
    <Dialog
      open={!isEmpty(uniqueErrors)}
      PaperProps={{
        elevation: 0,
        style: { maxWidth: "unset" },
      }}
    >
      <ModalTitle
        title={"Another record the same email already exists"}
        width={"730px"}
        closeModalDialog={handleClose}
        isShowCloseIcon
      />
      <Content>
        <Typography>
          There is already one person with the entered email registered in the
          system. Would you like to use the same record?
        </Typography>
        {uniqueErrors.map(uniqueError => (
          <RelatedRecords
            key={uniqueError.id}
            personId={uniqueError.id}
            contactRelationId={contactRelationId}
            setSendDisabled={setSendDisabled}
            isUserForm={isUserForm}
          />
        ))}
        <Typography>
          If you select <b>Yes</b> then the above mentioned users/contacts will
          be updated with the information you provided on the form. If you would
          like to change that information before update, select <b>Cancel</b>{" "}
          and try again.
        </Typography>
        <ActionButtons
          onSend={handleConfirm}
          onCancel={handleClose}
          disabled={sendDisabled}
          sendButtonText="YES"
        />
      </Content>
    </Dialog>
  );
};
DuplicatePersonDialog.propTypes = {
  dataComponentId: PropTypes.string.isRequired,
  contactRelationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isUserForm: PropTypes.bool,
  personPath: PropTypes.string,
};
