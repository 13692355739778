import React, { useMemo, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import pluralize from "pluralize";
import Add from "@material-ui/icons/Add";
import { Menu, MenuItem } from "@material-ui/core";

import { BWGridLocal, GridHeader } from "../../../../ui/BWGrid";
import GridTable from "../../../../ui/BWGrid/GridTable";
import { editableCellCreator } from "../../../../ui/BWGrid/EditableCell";
import { Button } from "../../../../layout/SectionHeader";
import propTypes from "../../../../../constants/propTypes";
import {
  columns,
  columnsOptions,
  getInputName,
  newVendorContact,
  rowInputs,
} from "./gridActions";
import { useLabeledText } from "../../../../inputs/TextInputWithError";

// eslint-disable-next-line max-lines-per-function
const Grid = ({
  values,
  isAdding,
  setIsAdding,
  formik,
  hasEmptyValues,
  handleSubmit,
  handleRemoveContact,
  vendorContacts,
  showSnackNotificationAction,
  hideAddContact,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    handleAddContact(formik)();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tableComponent = useMemo(
    () => ({
      CellComponent: editableCellCreator(rowInputs, getInputName(isAdding)),
    }),
    [isAdding]
  );

  const getRows = useCallback(
    ({ data, newVendorContact }) =>
      isAdding ? [newVendorContact, ...data] : data,
    [isAdding]
  );

  const handleAddContact = useCallback(
    formik => () => {
      if (isAdding) {
        handleSubmit();
      }
      setTimeout(() => {
        setIsAdding(true);
        formik.setFieldValue("newVendorContact", newVendorContact, false);
      }, 100);
    },
    [handleSubmit, isAdding, setIsAdding]
  );

  const handleAddExistingContact = useCallback(
    (
      formik,
      {
        contact: {
          person: { firstName, lastName, email, phoneNumber },
          jobTitle,
        },
        vendorId,
        id,
      }
    ) => () => {
      formik.setFieldValue("data", [
        ...values.data,
        {
          contact: {
            person: { firstName, lastName, email, phoneNumber },
            jobTitle,
          },
          vendorId,
          id,
        },
      ]);
      handleSubmit();
    },
    [handleSubmit, values.data]
  );

  const rows = getRows(values);
  const rowSet = new Set(rows.map(row => row.id));
  const parsedVendorContacts = vendorContacts.filter(
    vendorContact => !rowSet.has(vendorContact.id)
  );

  const labeledText = useLabeledText();
  return (
    <BWGridLocal
      id="ship-to-contacts"
      rows={rows}
      gridConfig={{ pageSize: 0, totalRows: values.data.length }}
      sorting={[]}
      tableComponents={tableComponent}
      editableNavigationDirection="horizontal"
      emptyStateText="No contacts created yet"
      hideFooter
      noBorder
    >
      <GridHeader
        headerText={`${values.data.length} ${pluralize(
          "Contact",
          values.data.length
        )}`}
      >
        <Button
          onClick={handleClick}
          text={"Add Contact"}
          icon={<Add />}
          aria-haspopup="true"
          disabled={hideAddContact}
        />
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {parsedVendorContacts.length === 0 ? (
            <MenuItem disabled={true}>{`No contacts to add`}</MenuItem>
          ) : null}
          {parsedVendorContacts.map((vendorContact, key) => {
            const {
              contact: { firstName, lastName, jobTitle },
            } = vendorContact;
            return (
              <MenuItem
                onClick={handleAddExistingContact(formik, vendorContact)}
                key={key}
              >
                {`${firstName} ${lastName} - ${jobTitle}`}
              </MenuItem>
            );
          })}
        </Menu>
      </GridHeader>
      <GridTable
        columns={columns}
        columnOptions={columnsOptions(
          handleSubmit,
          handleRemoveContact,
          hasEmptyValues,
          {
            isAdding,
            setIsAdding,
            formik,
            values,
            rows,
            showSnackNotificationAction,
            labeledText,
          }
        )}
      />
    </BWGridLocal>
  );
};

Grid.propTypes = {
  values: PropTypes.shape({
    data: PropTypes.array,
    newVendorContact: PropTypes.object,
  }),
  isAdding: PropTypes.bool,
  setIsAdding: PropTypes.func,
  formik: PropTypes.object,
  hasEmptyValues: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleRemoveContact: PropTypes.func,
  vendorContacts: PropTypes.arrayOf(propTypes.vendorContact),
  showSnackNotificationAction: PropTypes.func,
  hideAddContact: PropTypes.bool,
};

export default Grid;
