import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Person from "../Person/Person";
import Surplus from "./Surplus";

export const getUserInfo = ({
  person: { id, firstName, lastName, phoneNumber, email, fax, phone },
}) => {
  return {
    id,
    firstName,
    lastName,
    phoneNumber: phoneNumber || phone,
    email,
    fax,
  };
};

export const getMembers = (members, type, memberType) => {
  const filteredMembers = memberType
    ? members.filter(member => member.memberType === memberType)
    : members;
  return filteredMembers.map(item => getUserInfo(item[type]));
};

const PersonList = ({
  maxTags,
  members,
  type,
  memberType,
  personTypeLabel,
}) => {
  maxTags = maxTags !== undefined ? maxTags : members.length;
  const filteredMembers = getMembers(members, type, memberType);
  const displayMembers = filteredMembers.slice(0, maxTags);
  const surplusCount = filteredMembers.length - maxTags;
  return (
    <Fragment>
      {displayMembers.map((person, index) => (
        <Person
          key={index}
          person={person}
          isOverlap={index !== 0}
          personType={personTypeLabel}
          tabIndex="-1"
        />
      ))}
      {surplusCount > 0 && (
        <Surplus isOverlap={true} key="surplus">{`+${surplusCount}`}</Surplus>
      )}
    </Fragment>
  );
};

PersonList.propTypes = {
  maxTags: PropTypes.number,
  members: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
      memberType: PropTypes.string,
    })
  ),
  memberType: PropTypes.string,
  personTypeLabel: PropTypes.string,
  type: PropTypes.oneOf(["user", "contact"]),
};

PersonList.defaultProps = {
  members: [],
};

export default PersonList;
