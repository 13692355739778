import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { openModalDialog } from "../../../actions/layoutActions";
import AddressBookSectionLayout from "./AddressBookSectionLayout";
import { FieldArray } from "formik";
import propTypes from "../../../constants/propTypes";
import { FormikForm } from "../../forms";

const buildOpenDeleteHandler = (
  openModalDialog,
  formikArrayHelpers,
  onDeleteContact
) => (addressBookContact, name, rowId) => {
  openModalDialog(
    `Delete ${name}`,
    "AddressBookDeleteContactContainer",
    {
      addressBookContactId: addressBookContact.id,
      title: name,
      formikArrayHelpers,
      index: rowId,
      onDeleteContact,
    },
    false
  );
};

const buildOpenEditHandler = (
  openModalDialog,
  formikArrayHelpers,
  onCreateContact,
  onUpdateContact,
  addressBookId
) => (addressBookContact, rowId) => {
  openModalDialog(`Edit Contact`, "AddressBookCreateContactContainer", {
    formikArrayHelpers,
    contact: {
      ...addressBookContact.contact,
      scopeId: `${addressBookContact.scopeId}`,
    },
    index: rowId,
    onCreateContact,
    onUpdateContact,
    addressBookId,
  });
};

export class AddressBookContainer extends Component {
  static propTypes = {
    addressBook: PropTypes.object.isRequired,
    dataComponent: propTypes.dataComponent.isRequired,
    openModalDialog: PropTypes.func.isRequired,
    values: PropTypes.object,
    onCreateContact: PropTypes.func.isRequired,
    onDeleteContact: PropTypes.func.isRequired,
    onUpdateContact: PropTypes.func.isRequired,
  };

  createComponent = "AddressBookCreateContactContainer";

  handleAddClick = (formikArrayHelpers, onCreateContact, onUpdateContact) => {
    this.props.openModalDialog(
      "Create Contactssasasas",
      this.createComponent,
      {
        formikArrayHelpers,
        onCreateContact,
        onUpdateContact,
        addressBookId: this.props.addressBook.id,
        contact: {
          person: {
            firstName: "",
            lastName: "",
            phoneNumber: "",
            faxNumber: "",
            email: "",
          },
        },
      },
      true,
      false
    );
  };

  name = "addressBookContacts";

  render() {
    const {
      addressBook,
      dataComponent,
      values,
      openModalDialog,
      onCreateContact,
      onUpdateContact,
      onDeleteContact,
    } = this.props;

    return (
      <FormikForm>
        {() => (
          <FieldArray name={this.name}>
            {({ formikArrayHelpers }) => (
              <AddressBookSectionLayout
                addressBook={addressBook}
                dataComponent={dataComponent}
                rows={values[this.name]}
                onOpenAddModal={() =>
                  this.handleAddClick(
                    formikArrayHelpers,
                    onCreateContact,
                    onUpdateContact
                  )
                }
                onOpenEditModal={buildOpenEditHandler(
                  openModalDialog,
                  formikArrayHelpers,
                  onCreateContact,
                  onUpdateContact,
                  this.props.addressBook.id
                )}
                onOpenDeleteModal={buildOpenDeleteHandler(
                  openModalDialog,
                  formikArrayHelpers,
                  onDeleteContact
                )}
              />
            )}
          </FieldArray>
        )}
      </FormikForm>
    );
  }
}

export const mapDispatchToProps = {
  openModalDialog,
};

export default withRouter(
  connect(null, mapDispatchToProps)(AddressBookContainer)
);
