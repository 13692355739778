import { useMemo } from "react";

import { usePerformRetrieveList } from "../hooks/usePerformRetrieveList";

import VendorContact from "../../models/VendorContact";
import ClientContact from "../../models/ClientContact";
import ProjectMemberContact from "../../models/ProjectMemberContact";
import AddressBookContact from "../../models/AddressBookContact";
import ConsultingCompanyContact from "../../models/ConsultingCompanyContact";
import ProjectSiteContact from "../../models/ProjectSiteContact";
import get from "lodash.get";
import User from "../../models/User";
import Person from "../../models/Person";

const useRelatedVendorContacts = personId => {
  const vendorContactDataComponent = useMemo(
    () => ({
      dataComponentId: `${personId}-vendorContactRelatedRecordsDataComponentId`,
      model: VendorContact,
      includes: ["vendor"],
      apiRoute: "vendor-contacts",
    }),
    [personId]
  );
  const vendorContactFilters = useMemo(
    () => ({
      rootFilters: {
        $where: {
          "contact.personId": personId,
        },
      },
    }),
    [personId]
  );
  const { isLoading, data } = usePerformRetrieveList(
    vendorContactDataComponent,
    vendorContactFilters
  );
  const relations = data.map(vendorContact => ({
    contactRelationId: vendorContact.vendorId,
    relationName: "Vendor Contact",
    name: get(vendorContact, "vendor.name"),
  }));
  return { isLoading, relations };
};

const useRelatedClientContacts = personId => {
  const clientContactDataComponent = useMemo(
    () => ({
      dataComponentId: `${personId}-clientContactRelatedRecordsDataComponentId`,
      model: ClientContact,
      includes: ["client"],
      apiRoute: "client-contacts",
    }),
    [personId]
  );
  const clientContactFilters = useMemo(
    () => ({
      rootFilters: {
        $where: {
          "contact.personId": personId,
        },
      },
    }),
    [personId]
  );
  const { isLoading, data } = usePerformRetrieveList(
    clientContactDataComponent,
    clientContactFilters
  );
  const relations = data.map(clientContact => ({
    contactRelationId: clientContact.clientId,
    relationName: "Client Contact",
    name: get(clientContact, "client.name"),
  }));
  return { isLoading, relations };
};

const useRelatedProjectMemberContacts = personId => {
  const projectMemberContactDataComponent = useMemo(
    () => ({
      dataComponentId: `${personId}-projectMemberContactRelatedRecordsDataComponentId`,
      model: ProjectMemberContact,
      includes: ["project"],
      apiRoute: "project-member-contacts",
    }),
    [personId]
  );

  const projectMemberContactFilters = useMemo(
    () => ({
      rootFilters: {
        $where: {
          "contact.personId": personId,
        },
      },
    }),
    [personId]
  );
  const { isLoading, data } = usePerformRetrieveList(
    projectMemberContactDataComponent,
    projectMemberContactFilters
  );
  const relations = data.map(projectMemberContact => ({
    contactRelationId: projectMemberContact.projectId,
    relationName: "Project Member Contact",
    name: get(projectMemberContact, "project.name"),
  }));
  return { isLoading, relations };
};

const useRelatedAddressBookContacts = personId => {
  const addressBookContactDataComponent = useMemo(
    () => ({
      dataComponentId: `${personId}-addressBookContactRelatedRecordsDataComponentId`,
      model: AddressBookContact,
      includes: ["addressBook"],
      apiRoute: "address-book-contacts",
    }),
    [personId]
  );
  const addressBookContactFilters = useMemo(
    () => ({
      rootFilters: {
        $where: {
          "contact.personId": personId,
        },
      },
    }),
    [personId]
  );
  const { isLoading, data } = usePerformRetrieveList(
    addressBookContactDataComponent,
    addressBookContactFilters
  );
  const relations = data.map(addressBookContact => ({
    contactRelationId: addressBookContact.addressBookId,
    relationName: "Address Book Contact",
    name: get(addressBookContact, "addressBook.type"),
  }));
  return { isLoading, relations };
};

const useRelatedConsultingCompanyContacts = personId => {
  const consultingCompanyContactDataComponent = useMemo(
    () => ({
      dataComponentId: `${personId}-consultingCompanyContactRelatedRecordsDataComponentId`,
      model: ConsultingCompanyContact,
      includes: ["consultingCompany"],
      apiRoute: "consulting-company-contacts",
    }),
    [personId]
  );
  const consultingCompanyContactFilters = useMemo(
    () => ({
      rootFilters: {
        $where: {
          "contact.personId": personId,
        },
      },
    }),
    [personId]
  );
  const { isLoading, data } = usePerformRetrieveList(
    consultingCompanyContactDataComponent,
    consultingCompanyContactFilters
  );
  const relations = data.map(consultingCompanyContact => ({
    contactRelationId: consultingCompanyContact.consultingCompanyId,
    relationName: "Consulting Company Contact",
    name: get(consultingCompanyContact, "consultingCompany.name"),
  }));
  return { isLoading, relations };
};

const useRelatedProjectSiteContacts = personId => {
  const projectSiteContactDataComponent = useMemo(
    () => ({
      dataComponentId: `${personId}-projectSiteContactRelatedRecordsDataComponentId`,
      model: ProjectSiteContact,
      includes: ["projectSite"],
      apiRoute: "project-site-contacts",
    }),
    [personId]
  );
  const projectSiteContactFilters = useMemo(
    () => ({
      rootFilters: {
        $where: {
          "contact.personId": personId,
        },
      },
    }),
    [personId]
  );
  const { isLoading, data } = usePerformRetrieveList(
    projectSiteContactDataComponent,
    projectSiteContactFilters
  );
  const relations = data.map(projectSiteContact => ({
    contactRelationId: projectSiteContact.projectSiteId,
    relationName: "Project Site Contact",
    name: get(projectSiteContact, "projectSite.name"),
  }));
  return { isLoading, relations };
};

const useRelatedUsers = personId => {
  const userDataComponent = useMemo(
    () => ({
      dataComponentId: `${personId}-userRelatedRecordsDataComponentId`,
      model: User,
      apiRoute: "users",
    }),
    [personId]
  );
  const userFilters = useMemo(
    () => ({
      rootFilters: {
        $where: {
          personId,
        },
      },
    }),
    [personId]
  );
  const { isLoading, data } = usePerformRetrieveList(
    userDataComponent,
    userFilters
  );
  const relations = data.map(user => ({
    contactRelationId: user.id,
    relationName: "User",
    name: get(user, "name"),
  }));

  return { isLoading, relations };
};

const useRetrievePerson = personId => {
  const userDataComponent = useMemo(
    () => ({
      dataComponentId: `${personId}-personDataComponentId`,
      model: Person,
      apiRoute: "persons",
    }),
    [personId]
  );
  const userFilters = useMemo(
    () => ({
      rootFilters: {
        $where: {
          id: personId,
        },
      },
      params: {
        modifiers: ["withPersonProps"],
      },
    }),
    [personId]
  );
  const { isLoading, data } = usePerformRetrieveList(
    userDataComponent,
    userFilters
  );
  return { isLoading, person: get(data, "[0]") };
};

export const useRelatedRecords = personId => {
  const relatedVendorContacts = useRelatedVendorContacts(personId);
  const relatedClientContacts = useRelatedClientContacts(personId);
  const relatedProjectMemberContacts = useRelatedProjectMemberContacts(
    personId
  );
  const relatedAddressBookContacts = useRelatedAddressBookContacts(personId);
  const relatedConsultingCompanyContacts = useRelatedConsultingCompanyContacts(
    personId
  );
  const relatedProjectSiteContacts = useRelatedProjectSiteContacts(personId);
  const relatedUsers = useRelatedUsers(personId);
  const personRetrieval = useRetrievePerson(personId);

  const relations = [
    ...relatedVendorContacts.relations,
    ...relatedClientContacts.relations,
    ...relatedProjectMemberContacts.relations,
    ...relatedAddressBookContacts.relations,
    ...relatedConsultingCompanyContacts.relations,
    ...relatedProjectSiteContacts.relations,
    ...relatedUsers.relations,
  ];

  const isLoading = [
    relatedVendorContacts.isLoading,
    relatedClientContacts.isLoading,
    relatedProjectMemberContacts.isLoading,
    relatedAddressBookContacts.isLoading,
    relatedConsultingCompanyContacts.isLoading,
    relatedProjectSiteContacts.isLoading,
    relatedUsers.isLoading,
    personRetrieval.isLoading,
  ].some(Boolean);
  return { person: personRetrieval.person, relations, isLoading };
};
