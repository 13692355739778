import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { push } from "connected-react-router";
import VendorProjectsPage from "./VendorProjectsPage";
import { getDataComponentFlattenedRequestState } from "../../../reducers/dataComponentReducer";
import Project from "../../../models/Project";

export class VendorProjectsContainer extends Component {
  static propTypes = {
    vendorId: PropTypes.string,
    dataComponent: PropTypes.object.isRequired,
    push: PropTypes.func.isRequired,
  };

  render() {
    return (
      <VendorProjectsPage
        {...this.props}
        model={Project}
        includes={[
          "property.[entity, location]",
          "membersContacts",
          "projectLead.person",
        ]}
        apiRoute="projects"
      />
    );
  }
}

export const mapStateToProps = (state, ownProps) => ({
  vendorId: ownProps.match.params.vendorId,
  dataComponent: getDataComponentFlattenedRequestState(
    "VendorProjectsGrid",
    state
  ),
});

export default connect(mapStateToProps, { push })(VendorProjectsContainer);
