import React from "react";
import PropTypes from "prop-types";
import AddCircle from "@material-ui/icons/AddCircle";

import propTypes from "../../../../constants/propTypes";
import { BWGridAPI, GridHeader, GridTable } from "../../../ui/BWGrid";
import ConsultingCompanyContact from "../../../../models/ConsultingCompanyContact";
import { Status, FullName, ClientPortalAccess } from "./components";

const columns = [
  "contact.firstName",
  "contact.jobTitle",
  "contact.status",
  "canAccessClientPortal",
  "contact.fax",
  "contact.phone",
  "contact.email",
];

const columnOptions = {
  "contact.firstName": {
    title: "Name",
    bold: true,
    render: FullName,
  },
  "contact.jobTitle": { title: "Job Title" },
  "contact.status": {
    title: "Status",
    render: Status,
  },
  "contact.fax": {
    title: "Fax",
    align: "right",
  },
  "contact.phone": {
    title: "Phone Number",
    align: "right",
  },
  "contact.email": { title: "Email", align: "right" },
  canAccessClientPortal: {
    title: "Client Portal Access",
    filter: "select",
    fill: true,
    filterOptions: {
      operator: "equal",
      options: [
        { id: true, name: "Active" },
        { id: false, name: "Inactive" },
      ],
    },
    render: ClientPortalAccess,
  },
};

const getRowMenu = (
  handleEditContact,
  handleDeleteContact,
  handleResendAccessEmail
) => row => {
  const options = [
    { text: "Edit", onClick: handleEditContact },
    { text: "Delete", onClick: handleDeleteContact },
  ];

  if (row.canAccessClientPortal) {
    options.push(
      { separator: true },
      {
        text: "Resend Access Email",
        onClick: handleResendAccessEmail,
      }
    );
  }
  return options;
};
const apiFilters = consultingCompany => ({
  rootFilters: {
    $where: {
      consultingCompanyId: consultingCompany.id,
    },
  },
  sort: [
    { columnName: "contact.firstName", direction: "asc" },
    { columnName: "contact.lastName", direction: "asc" },
  ],
});

const Contacts = ({
  consultingCompany,
  gridDataComponent,
  handleAddContact,
  handleEditContact,
  handleDeleteContact,
  handleResendAccessEmail,
}) => (
  <BWGridAPI
    includes={["contact.person"]}
    model={ConsultingCompanyContact}
    dataComponent={gridDataComponent}
    apiRoute="consulting-company-contacts"
    apiFilters={apiFilters(consultingCompany)}
  >
    <GridHeader
      headerText={`${gridDataComponent.totalRows} Contacts`}
      actions={[
        {
          text: "ADD CONTACT",
          icon: <AddCircle />,
          handler: handleAddContact,
        },
      ]}
    />
    <GridTable
      columns={columns}
      columnOptions={columnOptions}
      rowMenu={getRowMenu(
        handleEditContact,
        handleDeleteContact,
        handleResendAccessEmail
      )}
    />
  </BWGridAPI>
);

Contacts.propTypes = {
  handleAddContact: PropTypes.func,
  handleEditContact: PropTypes.func,
  handleDeleteContact: PropTypes.func,
  gridDataComponent: propTypes.dataComponent,
  handleEditConsultingCompany: PropTypes.func,
  handleDeleteConsultingCompany: PropTypes.func,
  handleResendAccessEmail: PropTypes.func,
  consultingCompany: propTypes.consultingCompany,
};

Contacts.defaultProps = {
  consultingCompany: {},
};

export default Contacts;
